import React from 'react';
import Typography from '@material-ui/core/Typography';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {new Date().getFullYear()}
        {' © Dermatology Consultants of Asheville. All Rights Reserved'}
      </Typography>
    );
  }

export default Copyright;