import React from 'react';
import MaterialLink from '../components/MaterialLink';
import Copyright from '../components/Copyright';
import { Typography } from '@material-ui/core';
import './Footer.css';
import {Phone,Email,Print} from '@material-ui/icons';

const Footer = () => {
    return (
        <>
            <div className="footer-box">
                <div>
                    <h3>ADDRESS</h3>
                    <address>
                    218 Elkwood Ave, Suite 104<br></br>
                    Woodfin, NC 28804
                    </address>
                </div>
                <div>
                    <h3>CONTACT</h3>
                    <div className="footer-icons">
                        <Phone/>
                        {/* <p>828 484 4478</p> */}
                        <a className="clickable-numbers" href="tel:828-484-4478">828 484 4478</a>
                    </div>
                    <div className="footer-icons">
                        <Print/>
                        <p className="clickable-numbers">828 484 0068</p>
                    </div>
                    <div className="footer-icons">
                        <Email/>
                        {/* <a href="mailto:office@dermatologyasheville.com">office@dermatologyasheville.com</a> */}
                        {/* <p>office@dermatologyasheville.com</p> */}
                        <a className="clickable-numbers" href="mailto:office@dermatologyasheville.com?Subject=Patient%20Question" target="_top">office@dermatologyasheville.com</a>
                    </div>
                    
                </div>
            </div>
            <MaterialLink to='/privacy'>
                <Typography align="center">Privacy Policy</Typography>
            </MaterialLink>
            <Copyright/>
        </>
    )
}

export default Footer;