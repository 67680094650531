import React from 'react';
import {Drawer,List,makeStyles} from '@material-ui/core';
import Link from '../components/Link';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer({toggleDrawer,state}) {
  const classes = useStyles();
  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >

      <List component="nav">
        {['Home', 'Location','Providers','Services','Telemedicine', 'Telehealth', 'Contact'].map((text) => {
            let link = text;
            if(text==='Home'){
              link = ''
            }
            return <Link to={`/${link.toLowerCase()}`} primary={text} key={text}/>
          })}
      </List> 
    </div>
  );

  return (
    <div>
      <Drawer open={state} onClose={toggleDrawer(false)}>
        {sideList()}
      </Drawer>
    </div>
  );
}
