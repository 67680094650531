import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar,Toolbar,IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './MountainLogo';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    marginLeft: 'auto'
  }
}));

export default function TopBar({handleClick}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar >
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" aria-haspopup="true" onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Logo />
        </Toolbar>
      </AppBar>
    </div>
  );
}
