import React from 'react';
import Footer from '../components/Footer';
import {Container,Box} from '@material-ui/core';
import TopBar from '../components/TopBar';
import NavDrawer from '../components/NavDrawer';
import SEO from '../components/SEO';


const Layout = (props) => {

    const [state, setState] = React.useState(false);

    const toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState(open);
  };

    return(
      <>
        <SEO />
        <Container  maxWidth="md">
            <TopBar handleClick={toggleDrawer(true)}/>
            <NavDrawer toggleDrawer={toggleDrawer} state={state}/>
            <Box >
                {props.children}
                <Footer />
            </Box>
        </Container>
      </>
    )
}

export default Layout;