import React from 'react';
import { SvgIcon } from '@material-ui/core';
export default function MountainLogo() {
  
    return (
      <SvgIcon style={{ color: "white", marginLeft:'auto'}} viewBox="0 0 701.43 438.16">
            <path d="M299.63,0l-35,68.31c-4.59,4-15.5-5.74-25.83-9.19-13.2,16.08-37.31,58.55-41.33,66-6.89,12.06-27.55,55.68-40.76,57.41C142.35,184.83,29.27,400.66,0,435.1,10.91,454.62,108.49,376,122.84,331.2c22.38-17.22,50.51-23,64.29-48.79,18.37,15.5-25.26,69.46-21.24,83.23,0,0,16.65,22.39,76.92,22.39-35.59-55.1,22.38-64.86,10.9-103.9,21.24,17.8,34.44,31.58,62.57,35L300.78,256l88.4,89c19.52,19.52,41.9,38.46,68.31,47.64L400.08,306l-49.36-94.71c20.66,13.77,43.62,41.9,69.46,38.45C421.9,256,344.41,128,361.63,130.87c66,89.55,62.56,93,148.09,167.62C529.24,315.13,550.48,345.55,574,353l-12.63-55.68c43,47.64,66,137.19,140.06,138.91-2.87-6.89-37.31-43.63-60.27-102.18-33.29-49.94-72.9-111.35-85.53-121.11-4.59,7.46-32.14,37.31-37.88,43.62C512,251.4,474.7,215.23,456.9,194L357,56.25C340.39,33.29,327.19,8,299.63,0Zm9.19,121.12c2.29,4-4-41.33-4-37.31,0,3.44,21.24,47.06,27,57.4-1.72-4.6-29.28-15.5-29.28-15.5,2.87,54.53,4,44.2,14.35,89l-51.66-38.46.58,57.4-33.3-40.18c29.85,4.59,12.63-24.11,12.63-43.05l45.35,26.41-4.59-66s12.05,8,23,10.34Z"/>
      </SvgIcon>
      
    );
  }