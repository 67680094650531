import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {Link as MuiLink} from '@material-ui/core';
import { ListItem, ListItemText } from '@material-ui/core';


export default function ListItemLink(props) {
  const { primary, to } = props;
  const renderLink = React.useMemo(
    () => React.forwardRef((linkProps, ref) => (
        <MuiLink component={GatsbyLink} ref={ref} to={to} {...linkProps}/>
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}